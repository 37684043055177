//@flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {connect} from "react-redux";
import getObjProp from "../../../../../../../utils/getObjProp";
import type {
  managerIds,
  merchantParams,
  processorIds,
  sourceOptions,
} from "../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../services/localization/text";
import SectionHeader from "../../../../../../../components/composed/SectionHeader/SectionHeader";
import PaynetParamsItem from "./PaynetParamsItem";
import {agentEditPageIds} from "../../../../../../../tests/testIds";
import contractMainDataActions
  from "../../../../../../../store/organization/item/contractMainData/contractMainDataActions";
import type {onGetContractMainDataProp} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import {defineTooltipForSelectOptions} from "../../GroupFinTermsEdit/groupFinTermsEdit/SelectGroupFinTermsType";
import ConnectedFormGroup from "../../../../../../../components/composed/ConnectedFormGroup/ConnectedFormGroup";

type Props = {
  merchantParams: merchantParams,
  managerIds: managerIds,
  processorIds: processorIds,
  isViewMode?: boolean,
  sourceOptions: sourceOptions,
  onGetContractMainDataProp: onGetContractMainDataProp
};

export const paynetSources = ["PNE", "PNE_EU"];
const idsFields = ['merchantParams', 'managerIds', 'processorIds']
const PaynetParams = (props: Props) => {
  const {sourceOptions, contractMainData, onGetContractMainDataProp} = props;

  const [sourceSelectOptions, setSourceSelectOptions] = useState<sourceOptions[]>([])
  const [sourceOption, setSourceOption] = useState<sourceOptions>()
  const isMerchantParamsViewMode = props.isViewMode;
  const isManagerIdsViewMode = props.isViewMode;

  const onChangeSource = (source: sourceOptions) => {
    handleSourceSelect(source)
    setSourceOption(source)
  }

  function changeSource(source, fieldWithIds) {
    let ids = contractMainData[fieldWithIds];
    if (ids.length > 0) {
      onGetContractMainDataProp(fieldWithIds, [{...ids[0], source}]);
    }
  }

  const handleSourceSelect = (source) => {
    // для каджого поля устанавливаем значение, в случае если не поддерживается больше одного источника
    // ЕСЛИ ТОЛЬКО ОДИН ИСТОЧНИК
    // обновляем свойства  для merchantParams, managerIds, processorIds
    ['merchantParams', 'managerIds', 'processorIds'].forEach(s => changeSource(source, s));
  };

  useEffect(() => {
    const filteredPaynetSources = paynetSources.filter(item => sourceOptions.includes(item));
    setSourceSelectOptions(
      filteredPaynetSources.map((item, index) => {
        return {
          value: item,
          label: (<div className={'flex ai_c'}>
            {text[item]}
            {defineTooltipForSelectOptions(index, 'groupFinTermSourceTooltip', item, filteredPaynetSources.length)}
          </div>)
        };
      }));
  }, [sourceOptions, sourceOption]);

  useEffect(() => {
    if (sourceOption || sourceSelectOptions.length === 0) {
      return
    }

    const defaultSource = idsFields
      .map(s => contractMainData[s])
      .filter(s => s && s.length > 0)
      .flat()
      .map(({source}) => source)
      .find(source => source) || null;

    if (defaultSource !== null) {
      setSourceOption(defaultSource)
    }

  }, [contractMainData, sourceSelectOptions, sourceOption]);

  return (
    <>
      {/*<pre>{JSON.stringify(contractMainData, null, 4)}</pre>*/}
      <ConnectedFormGroup
        formFieldType="switcher"
        propName="source"
        propValue={sourceOption}
        onGetPropValue={(propName, value) => {
          onChangeSource(value)
        }}
        label={text.source}
        options={sourceSelectOptions}
        idForTest={"source-options"}
        disabled={props.isViewMode}
        noOptionsMessage={"No sources in contract"}
      />

      <SectionHeader sectionHeaderTitle={text.merchantId} addClasses={"mb_3du"}/>

      <PaynetParamsItem isViewMode={isMerchantParamsViewMode}
                        propName={"merchantParams"}
                        paynetParamName={"merchantId"}
                        paynetParams={props.merchantParams}
                        paynetSource={sourceOption}
                        selectPlaceholder={text.merchantIdPlaceholder}
                        selectSourceTestId={agentEditPageIds.selectMerchantIdSource}
                        selectPaynetParamTestId={agentEditPageIds.inputMerchantIds}
                        buttonAddPaynetParamItemTestId={agentEditPageIds.buttonAddMerchantIdSource}
                        buttonAddPaynetParamItemLabel={text.addSourceAndMerchantId}
                        onGetPaynetParam={onGetContractMainDataProp}
                        paynetSourceTextLabel={text.source}
                        paynetSourceSelectPlaceholder={text.select.placeholder.source}
                        paynetSourcePropName={"source"}
      />
      <SectionHeader sectionHeaderTitle={text.managerId} addClasses={"mb_3du"}/>
      <PaynetParamsItem isViewMode={isManagerIdsViewMode}
                        propName={"managerIds"}
                        paynetParamName={"managerId"}
                        paynetParams={props.managerIds}
                        paynetSource={sourceOption}
                        selectPlaceholder={text.managerIdPlaceholder}
                        selectSourceTestId={agentEditPageIds.selectManagerIdSource}
                        selectPaynetParamTestId={agentEditPageIds.inputManagerIds}
                        buttonAddPaynetParamItemTestId={agentEditPageIds.buttonAddManagerIdSource}
                        buttonAddPaynetParamItemLabel={text.addSourceAndManagerId}
                        onGetPaynetParam={onGetContractMainDataProp}
                        paynetSourceTextLabel={text.source}
                        paynetSourceSelectPlaceholder={text.select.placeholder.source}
                        paynetSourcePropName={"source"}
      />
      <SectionHeader sectionHeaderTitle={text.processorId} addClasses={"mb_3du"}/>
      <PaynetParamsItem isViewMode={isManagerIdsViewMode}
                        propName={"processorIds"}
                        paynetParamName={"processorId"}
                        paynetParams={props.processorIds}
                        paynetSource={sourceOption}
                        selectPlaceholder={text.processorIdPlaceholder}
                        selectSourceTestId={agentEditPageIds.selectProcessorIdSource}
                        selectPaynetParamTestId={agentEditPageIds.inputProcessorIds}
                        buttonAddPaynetParamItemTestId={agentEditPageIds.buttonAddProcessorIdSource}
                        buttonAddPaynetParamItemLabel={text.addSourceAndProcessorId}
                        onGetPaynetParam={onGetContractMainDataProp}
                        paynetSourceTextLabel={text.source}
                        paynetSourceSelectPlaceholder={text.select.placeholder.source}
                        paynetSourcePropName={"source"}
      />
    </>
  );
};


const mapStateToProps = state => {
  const sourceOptions = state.organization.organizationData.configFinTerms.sourceOptions ?
    state.organization.organizationData.configFinTerms.sourceOptions : [];
  return {
    merchantParams: getObjProp(state.organization.item.contractMainData, "merchantParams"),
    managerIds: getObjProp(state.organization.item.contractMainData, "managerIds"),
    processorIds: getObjProp(state.organization.item.contractMainData, "processorIds"),
    sourceOptions,
    contractMainData: state.organization.item.contractMainData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetContractMainDataProp: (prop, data) => dispatch({
      type: contractMainDataActions.CONTRACT_MAIN_DATA_PROP_SET, value: {
        prop: prop,
        data: data
      }
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaynetParams);
