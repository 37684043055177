//@flow
import * as React from 'react';
import type {managerIds, merchantParams, tagsList} from "../../../../../../../services/flowTypes/dataFlowTypes";
import OutputOrWarn from "../../../../../../../components/simple/Containers/AlertCard/OutputOrWarn";
import NFormGroup from "../../../../../../../components/simple/FormComponents/NFormGroup/NFormGroup";
import text from "../../../../../../../services/localization/text";
import FormTagSelect from "../../../../../../../components/simple/FormComponents/FormTagSelect/FormTagSelect";
import update from 'immutability-helper';
import type {propName, sourceOptions} from "../../../../../../../services/flowTypes/componentFlowTypes";
import type {id, label, placeholder} from "../../../../../../../services/flowTypes/appFlowTypes";

type Props = {
  paynetParams: merchantParams | managerIds,
  propName: propName,
  paynetParamName: string,
  onGetPaynetParam: Function,
  isViewMode?: boolean,
  selectPlaceholder: placeholder,
  selectSourceTestId: Function,
  selectPaynetParamTestId: Function,
  buttonAddPaynetParamItemTestId: id,
  buttonAddPaynetParamItemLabel: label,
  paynetSource?: sourceOptions,
};

class PaynetParamsItem extends React.Component <Props> {
  handlePaynetParamChange = (value: tagsList, paynetParamsIndex) => {
    const paynetParams = this.props.paynetParams.length === 0
      ? [{
        source: this.props.paynetSource,
        [this.props.paynetParamName]: [...value]
      }]
      : update(this.props.paynetParams, {
      [paynetParamsIndex]: {
        [this.props.paynetParamName]:
          {$set: value}
      }
    });
    this.props.onGetPaynetParam(this.props.propName, paynetParams);
  };

  render() {
    const normalizePaynetParams = this.props.paynetParams === "Is not found!" || this.props.paynetParams.length === 0 ? [{
      source: this.props.paynetSource,
      [this.props.paynetParamName]: []
    }] : this.props.paynetParams;

    const paynetParams = normalizePaynetParams.map((item, i) => {
      return this.props.isViewMode ?
        (<div className={"flex_jc-sb mb_3du"} key={"paynetParamsItem" + i}>
            <NFormGroup label={text[this.props.paynetParamName]} addClasses={"w_three-quarter-card"}>
              <FormTagSelect value={item[this.props.paynetParamName]}
                             disabled={true}
                             placeholder={this.props.selectPlaceholder}/>
            </NFormGroup>
          </div>
        )
        :
        (<div className={"mb_3du"} key={"paynetParamsItem" + i}>
          <NFormGroup label={text[this.props.paynetParamName]} addClasses={"w_100"}>
            <FormTagSelect value={item[this.props.paynetParamName]}
                           onChange={value => this.handlePaynetParamChange(value, i)}
                           delimiters={[',']}
                           mask={'\\d'}
                           placeholder={this.props.selectPlaceholder}
                           idForTest={this.props.selectPaynetParamTestId(i)}/>
          </NFormGroup>
        </div>);
    });
    return (
      <OutputOrWarn propName={this.props.propName} propValue={this.props.paynetParams} component={
        <>
          {paynetParams}
        </>
      }/>
    );
  }
}

export default PaynetParamsItem;