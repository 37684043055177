//@flow
import * as React from 'react';
import type {children, id, modalIsShown} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../../services/flowTypes/uiKitFlowTypes";
import text from "../../../../services/localization/text";
import NModal from "../NModal/NModal";
import type {onModalActionBtnClick} from "../../../../services/flowTypes/componentFlowTypes";

type Props = {
  id?: id,
  addClasses?: addClasses,
  initActionBtn: React.Node,
  children: children,
  onModalActionBtnClick: onModalActionBtnClick,
  disabledBtn: boolean
};
type State = {
  modalIsShown: modalIsShown
};

class ConfirmActionModal extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsShown: false
    };
  }
  onModalActionBtnClick = () => {
    this.props.onModalActionBtnClick();
    this.cancelModal();
  };
  cancelModal = () => {
    this.setState({modalIsShown: false});
  };
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  render(): React$Node {
    const onClick = this.props.disabledBtn ? null : this.showModal;
    return (
      <>
        <div className={`flex ${this.props.addClasses}`} onClick={onClick}>
          {this.props.initActionBtn}
        </div>
        {this.state.modalIsShown ? (
          <NModal
            modalTitle={text.confirmActionModal.modalTitle}
            onModalActionBtnClick={this.onModalActionBtnClick}
            onModalCancelBtnClick={this.cancelModal}
            modalActionBtnText={text.confirmActionModal.modalActionBtnText}
            modalCancelBtnText={text.confirmActionModal.modalCancelBtnText}
          >
            {this.props.children}
          </NModal>
        ) : null}
      </>
    );
  }
}

export default ConfirmActionModal;