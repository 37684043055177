//@flow
import * as React from 'react';
import {baseClass} from "../../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {children, id} from "../../../../services/flowTypes/appFlowTypes";
import type {addClasses, hideInUiKit} from "../../../../services/flowTypes/uiKitFlowTypes";
import './NModal.css';
import NCard from "../../Containers/NCard/NCard";
import SectionHeader from "../../../composed/SectionHeader/SectionHeader";
import type {
  isModalActionBtnDisabled,
  modalActionBtnText,
  modalCancelBtnText, onModalActionBtnClick,
  onModalCancelBtnClick, sectionHeaderSubTitle,
  sectionHeaderTitle
} from "../../../../services/flowTypes/componentFlowTypes";
import BtnCloseModal from "../../Buttons/BtnCloseModal/BtnCloseModal";
import BtnNewItem from "../../Buttons/BtnNewItem/BtnNewItem";
import * as ReactDOM from "react-dom";
import {contractEditPageIds} from "../../../../tests/testIds";
import ForbidScrollUnder from "../../../service/ForbidScrollUnder/ForbidScrollUnder";
import text from "../../../../services/localization/text";

type Props = {
  id?: id,
  addClasses?: addClasses,
  isModalDocumentEdit?: boolean,
  children: children,
  modalTitle: sectionHeaderTitle,
  onModalCancelBtnClick?: onModalCancelBtnClick,
  onModalActionBtnClick?: onModalActionBtnClick,
  modalCancelBtnText?: modalCancelBtnText,
  modalActionBtnText?: modalActionBtnText,
  hideInUiKit?: hideInUiKit,
  forbidBodyHiddenOverflow?: boolean,
  isModalActionBtnDisabled?: isModalActionBtnDisabled,
  modalSubTitle?: sectionHeaderSubTitle,
  isContentOverflowScroll?: boolean,
  modalFullWidthBtn?: React.Node,
  isDeleteProhibitedMode?: boolean
};

class NModal extends React.Component <Props> {
  render(){
    const closeModalBtn = this.props.onModalCancelBtnClick ?
      (<BtnCloseModal id={contractEditPageIds.buttonModalCancelIco} onClick={this.props.onModalCancelBtnClick}/>) : null;
    const nModalWrapperClassName = this.props.hideInUiKit ? "NModal__wrapper NModal__wrapper_hide" : "NModal__wrapper";
    const NModal = (
      <ForbidScrollUnder forbidBodyHiddenOverflow={this.props.forbidBodyHiddenOverflow}>
        <div id={this.props.id} className={nModalWrapperClassName}>
          <div className='NModal_body'>
            <div className={baseClass("NModal", this.props.addClasses)}>
              <NCard isModalDocumentEdit={this.props.isModalDocumentEdit} addClasses={'NModal_min-w-content'}>
                <SectionHeader isModalDocumentEdit={this.props.isModalDocumentEdit}
                               sectionHeaderTitle={this.props.modalTitle}
                               sectionHeaderSubTitle={this.props.modalSubTitle}
                               sectionHeaderBtn={closeModalBtn}/>
                {this.props.isContentOverflowScroll ? <div className="NModal__contentOverflowScroll">{this.props.children}</div>
                  : this.props.children}
                {(this.props.onModalCancelBtnClick && this.props.onModalActionBtnClick) ? (
                  <div className={this.props.isModalDocumentEdit ? "NModal__btns-container NModal__btns-container-border-top" : "NModal__btns-container"}>
                    {this.props.modalFullWidthBtn}
                    <BtnNewItem id={contractEditPageIds.buttonModalCancel} addClasses={"BtnNewItem_minWidth160 BtnNewItem_inverse"}
                                onClick={this.props.onModalCancelBtnClick}>
                      {this.props.modalCancelBtnText}
                    </BtnNewItem>
                    <BtnNewItem id={contractEditPageIds.buttonModalAct} onClick={this.props.onModalActionBtnClick}
                                addClasses={"BtnNewItem_minWidth160"}
                                disabled={this.props.isModalActionBtnDisabled}>
                      {this.props.modalActionBtnText}
                    </BtnNewItem>
                  </div>
                ) : null}
                {this.props.isDeleteProhibitedMode ? (
                  <div className="NModal__btns-container NModal__btns-container-border-top">
                    <BtnNewItem id={contractEditPageIds.buttonModalCancel} addClasses={"BtnNewItem_inverse BtnNewItem_full-width"}
                                onClick={this.props.onModalCancelBtnClick}>
                      {text.modalCloseBtnLabel}
                    </BtnNewItem>
                  </div>
                ) : null}
              </NCard>
            </div>
          </div>
        </div>
      </ForbidScrollUnder>
    );
    let modal = document.getElementById('modal');
    return modal ?
        ReactDOM.createPortal(NModal, modal)
        : null;
  }
}

export default NModal;