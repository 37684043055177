//@flow
import * as React from 'react';
import {connect} from "react-redux";
import RenderOnReady from "../../../../../../../../../../components/service/RenderOnReady/RenderOnReady";
import SourceParamFormGroup from "./SourceParamFormGroup";
import type {
  formFieldType, labelTooltip, propName, selectOptions,
  sourceParamIndex
} from "../../../../../../../../../../services/flowTypes/componentFlowTypes";
import type {isReadyToRender, label, placeholder} from "../../../../../../../../../../services/flowTypes/appFlowTypes";
import defineFormFieldType from "../../../../../../../../../../utils/defineFormFieldType";
import type {
  groupFinTermSource,
  paramMask,
  sourceParamName, sourceParams,
  sourceParamType
} from "../../../../../../../../../../services/flowTypes/dataFlowTypes";
import text from "../../../../../../../../../../services/localization/text";
import createOption from "../../../../../../../../../../utils/createOption";
import {groupFinTermEditPageIds} from "../../../../../../../../../../tests/testIds";
import QuestionTooltip
  from "../../../../../../../../../../components/simple/Containers/QuestionTooltip/QuestionTooltip";

type Props = {
  sourceParamIndex: sourceParamIndex,
  sourceParamType: sourceParamType,
  sourceParamName: sourceParamName,
  configSourceParams: sourceParams,
  sourceParamMask?: paramMask,
  source: groupFinTermSource
};
type State = {
  isReadyToRender: isReadyToRender,
  formFieldType: formFieldType,
  propName: propName,
  label: label,
  options: selectOptions,
  labelTooltip?: labelTooltip,
  placeholder: placeholder
};

class SourceParam extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: false,
      formFieldType: '',
      propName: '',
      label: '',
      placeholder: '',
      options: [],
      labelTooltip: null
    };
  }
  setPropName = () => {
    return 'sourceParam' + this.props.sourceParamIndex + this.props.sourceParamName;
  };
  setOptions = () => {
    const configSourceParam = this.props.configSourceParams.find(item => item.name === this.props.sourceParamName);
    const options = configSourceParam && configSourceParam.options ? configSourceParam.options : null;
    if (options) {
      return options.map(item => {
        return createOption(item);
      });
    } else {
      return [];
    }
  };
  setLabelTooltip = () => {
    const labelTooltipText = text.sourceParamTooltip[this.props.sourceParamName];
    return labelTooltipText && this.props.source === 'REDMINE' ? (
      <QuestionTooltip isHighZIndex={true}>{labelTooltipText}</QuestionTooltip>
    ) : null;
  };
  componentDidMount(){
    this.setState({
      isReadyToRender: true,
      formFieldType: defineFormFieldType(this.props.sourceParamType),
      propName: this.setPropName(),
      label: text.sourceParams.editLabel[this.props.source][this.props.sourceParamName],
      options: this.setOptions(),
      placeholder: this.setOptions() ? text.select.placeholder[this.props.sourceParamName] : null,
      labelTooltip: this.setLabelTooltip()
    });
  }
  render() {
    const inputDelimiter = this.state.formFieldType === 'input-with-delimiter' ? ',' : null;
    return (
      <RenderOnReady isReadyToRender={this.state.isReadyToRender}>
        <SourceParamFormGroup
          sourceParamIndex={this.props.sourceParamIndex}
          formFieldType={this.state.formFieldType}
          propName={this.state.propName}
          label={this.state.label}
          options={this.state.options}
          placeholder={this.state.placeholder}
          idForTest={groupFinTermEditPageIds.inputSourceParam(this.props.sourceParamName)}
          selectInputId={groupFinTermEditPageIds.selectInputSourceParam(this.props.sourceParamName)}
          isSearchable={true}
          inputDelimiter={inputDelimiter}
          mask={this.props.sourceParamMask}
          labelTooltip={this.state.labelTooltip}
        />
      </RenderOnReady>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const source = state.organization.item.groupFinTermsEdit.source;
  const configSourceParams = source ? state.organization.organizationData.configFinTerms.configSourceParams[source].sourceParams : [];

  if (!configSourceParams) {
    console.error("No sourceParams for such source!");
    return {
      source: source,
      sourceParamType: '',
      sourceParamName: '',
      configSourceParams
    };
  }

  let sourceParamName = state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].name;
  let sourceParamMask = configSourceParams.find(({name})=> name === sourceParamName);

  return {
    source: source,
    sourceParamType: state.organization.item.sourceParamsEdit[ownProps.sourceParamIndex].type,
    sourceParamName,
    sourceParamMask: sourceParamMask['mask'],
    configSourceParams: configSourceParams
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     onTest: (value) => dispatch({type:, value:})
//   };
// };

export default connect(mapStateToProps, null)(SourceParam);