//@flow
import * as React from 'react';
import {baseClass} from "../../../pages/UiKit/newUiKit/utilities/baseClass";
import type {id} from "../../../services/flowTypes/appFlowTypes";
import type {addClasses} from "../../../services/flowTypes/uiKitFlowTypes";
import './SectionHeader.css';
import NHeading3 from "../../simple/TextComponents/NHeading3/NHeading3";
import type {
  sectionHeaderBtn,
  sectionHeaderSubTitle,
  sectionHeaderTitle
} from "../../../services/flowTypes/componentFlowTypes";
import Text1 from "../../simple/TextComponents/Text1/Text1";
import NHeading1 from "../../simple/TextComponents/NHeading1/NHeading1";

type Props = {
  id?: id,
  addClasses?: addClasses,
  sectionHeaderTitle: sectionHeaderTitle,
  sectionHeaderSubTitle?: sectionHeaderSubTitle,
  sectionHeaderBtn?: sectionHeaderBtn,
  isModalDocumentEdit?: boolean
};

const SectionHeader = (props: Props) => {
  const addClasses = props.isModalDocumentEdit ? props.addClasses + ' SectionHeader_border-bottom' : props.addClasses;
  return (
    <div id={props.id} className={baseClass("SectionHeader", addClasses)}>
      <div>
        {props.isModalDocumentEdit ? (
          <NHeading1 addClasses={"NHeading1_lh32"}>{props.sectionHeaderTitle}</NHeading1>
        ) : (
          <NHeading3>{props.sectionHeaderTitle}</NHeading3>
        )}
        {props.sectionHeaderSubTitle ? (
          <Text1>{props.sectionHeaderSubTitle}</Text1>
        ) : null}
      </div>
      {props.sectionHeaderBtn}
    </div>
  );
};

export default SectionHeader;