//@flow
import * as React from 'react';
import {
  actTemplatePath,
  actTemplatesSuffix,
  addAgreementsSuffix,
  addServicePath,
  addServicesSuffix,
  agentsSuffix,
  contractsSuffix,
  contrPath,
  contrPathEdit,
  contrPathView,
  documentsSuffix,
  groupFinTermPath,
  groupFinTermsSuffix,
  mainDataSuffix,
  newActTemplateSuffix,
  newAddServiceSuffix,
  newContractSuffix,
  newGroupFinTermSuffix,
  orgPath,
  sourcesMainSuffix
} from "../../../services/urlStrings/urlStrings";
import PrivateRoute from "./PrivateRoute";
import ability from "../../../services/permissions/permissions";
import ContractMainDataEditButton
  from "../../../pages/Contracts/pages/Contract/pages/ContractMainDataView/ContractMainDataEditButton";
import ContractMainDataControls
  from "../../../pages/Contracts/pages/Contract/pages/ContractMainDataEdit/contractMainDataEdit/ContractMainDataControls";
import type {contractId, organizationId} from "../../../services/flowTypes/dataFlowTypes";
import NewGroupFinTermButton
  from "../../../pages/Contracts/pages/Contract/pages/GroupFinTermsView/groupFinTermsView/NewGroupFinTermButton";
import GroupFinTermsControls
  from "../../../pages/Contracts/pages/Contract/pages/GroupFinTermsEdit/groupFinTermsEdit/GroupFinTermsControls";
import GroupFinTermEditButton from "../../../pages/Contracts/pages/Contract/pages/FinTermView/GroupFinTermEditButton";
import ButtonAddAgents
  from "../../../pages/Contracts/pages/Contract/pages/ContractAgents/contractAgents/ButtonAddAgents";
import ButtonAddContracts
  from "../../../pages/Contracts/pages/Contract/pages/ContractLinkedContracts/contractLinkedContracts/ButtonAddContracts";
import UploadContractDocumentButton
  from "../../../pages/Contracts/pages/Contract/pages/ContractDocuments/UploadContractDocumentButton";
import NewActTemplateButton
  from "../../../pages/Contracts/pages/Contract/pages/ActTemplatesView/actTemplateView/NewActTemplateButton";
import GenerateReportButton
  from "../../../pages/Contracts/pages/Contract/pages/ActTemplatesView/actTemplateView/GenerateReportModal/GenerateReportButton";
import ActTemplateEditButton from "../../../pages/Contracts/pages/Contract/pages/ActTemplateView/ActTemplateEditButton";
import ActTemplatesControls
  from "../../../pages/Contracts/pages/Contract/pages/ActTemplatesEdit/actTemplatesEdit/ActTemplatesControls";
import ButtonNewAddAgreement
  from "../../../pages/Contracts/pages/Contract/pages/AddAgreements/addAgreements/ButtonNewAddAgreement";
import ButtonNewAddService
  from "../../../pages/Contracts/pages/Contract/pages/AddServices/addServices/ButtonNewAddService";
import AddServiceNavigationButtons
  from "../../../pages/Contracts/pages/Contract/pages/AddServiceEdit/AddServiceNavigationButtons";
import {permissionsStrValues} from "../../../configData/permissionsStrValues";

type Props = {
  organizationId: organizationId,
  contractId: contractId
};

const ContractButtonsRoutes = (props: Props) => {
  return (
    <>
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + mainDataSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ContractMainDataEditButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathEdit(':id') + mainDataSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ContractMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + sourcesMainSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId, pathSuffix: sourcesMainSuffix}}
                    component={ContractMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + newContractSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ContractMainDataControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contract.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + groupFinTermsSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={NewGroupFinTermButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + addAgreementsSuffix}
                    addProps={{contractId: props.contractId}}
                    component={ButtonNewAddAgreement}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.addAgreement.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathEdit(':id') + groupFinTermPath(':groupId')}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={GroupFinTermsControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPath(':id') + newGroupFinTermSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={GroupFinTermsControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + groupFinTermPath(':groupId')}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={GroupFinTermEditButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.finTerm.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + agentsSuffix}
                    addProps={{contractId: props.contractId}}
                    component={ButtonAddAgents}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contractLinkedAgent.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + contractsSuffix}
                    addProps={{contractId: props.contractId}}
                    component={ButtonAddContracts}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contractLinkedContract.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + documentsSuffix}
                    addProps={{contractId: props.contractId}}
                    component={UploadContractDocumentButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contractDocument.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + documentsSuffix + '/folder/:folderId'}
                    addProps={{contractId: props.contractId}}
                    component={UploadContractDocumentButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.contractDocument.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + actTemplatesSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={NewActTemplateButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathEdit(':id') + actTemplatePath(':actTemplateId')}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ActTemplatesControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + actTemplatePath(':actTemplateId')}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={GenerateReportButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.report.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + actTemplatePath(':actTemplateId')}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ActTemplateEditButton}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.update)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPath(':id') + newActTemplateSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ActTemplatesControls}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.actTemplate.create)} />
      <PrivateRoute exact path={orgPath(':organizationId') + contrPathView(':id') + addServicesSuffix}
                    addProps={{organizationId: props.organizationId, contractId: props.contractId}}
                    component={ButtonNewAddService}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
      <PrivateRoute path={orgPath(':organizationId') + contrPath(':id') + newAddServiceSuffix}
                    component={AddServiceNavigationButtons}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.addService.create)} />
      <PrivateRoute path={orgPath(':organizationId') + contrPathEdit(':id') + addServicePath(':addServiceId')}
                    component={AddServiceNavigationButtons}
                    canUseRoute={ability.can('usePermission', permissionsStrValues.addService.update)} />
    </>
  );
};

export default ContractButtonsRoutes;