//@flow

import React, {useEffect} from "react";

import {connect} from "react-redux";
import text from "../../../../../../services/localization/text";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import PaynetParams from "../ContractMainDataEdit/contractMainDataEdit/PaynetParams";
import {contractEditPageIds} from "../../../../../../tests/testIds";
import ContractMainDataFormGroup from "../ContractMainDataEdit/contractMainDataEdit/ContractMainDataFormGroup";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import type {contract, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import itemDataParamsEditActions
  from "../../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import type {onSetItemDataParamsEdit, } from "../../../../../../services/flowTypes/propFnFlowTypes";

type Props = {
  organizationId: organizationId,
  contractMainData: contract,
  onSetItemDataParamsEdit: onSetItemDataParamsEdit
}

const ContractSourcesView = (props: Props) => {

  const {organizationId, contractMainData, onSetItemDataParamsEdit} = props;

  useEffect(()=> {
    if(contractMainData.params && contractMainData.params.length > 0 && onSetItemDataParamsEdit) {
      onSetItemDataParamsEdit(contractMainData.params)
    }
  }, [contractMainData, onSetItemDataParamsEdit])
  return <NCard>
    {contractMainData.isNotCompliedWithPaynetParams ? (
      <AlertPaynetSync agentIdIsNotCompliedWIthPaynetParams={contractMainData.agentIdIsNotCompliedWIthPaynetParams}
                       organizationId={organizationId}/>
    ) : null}
    <ContractMainDataFormGroup propName={"ignoreGates"}
                               label={text.agent.ignoreGates}
                               formFieldType={'checkbox-boolean'}
                               addClasses={'w_half-card'}
                               idForTest={contractEditPageIds.ignoreGates}
    />
    <PaynetParams/>
  </NCard>
}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    contractMainData: state.organization.item.contractMainData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetItemDataParamsEdit: (data) => dispatch({type: itemDataParamsEditActions.ITEM_DATA_PARAMS_SET, value: data}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractSourcesView);
